import { HomeIcon } from '@heroicons/react/24/outline'
import { useCallback, useEffect, useState } from 'react'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { useAuthState } from '../../auth/AuthProvider'
import { type Patient } from '../../model/patient'
import { setCurrentPatient } from '../../reducers/currentPatientReducer'
import { getPatientService } from '../../services/Services'
import { useAppDispatch } from '../../store/hooks'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
  Avatar,
  AvatarBadge,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Wrap,
  WrapItem
} from '@chakra-ui/react'
import useAuth from '../../auth/hooks/useAuth'
import { setVisits } from '../../reducers/currentVisitsReducer'

export default function Navbar() {
  const dispatch = useAppDispatch()
  const { photoURL, uid, fullName } = useAuthState()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [items, setItems] = useState<Patient[]>([])

  const handleSearchSelect = (selection: Patient) => {
    dispatch(setCurrentPatient(selection))
    dispatch(setVisits([]))
    navigate('/patients/' + selection.id)
  }

  const handleSearchInput = (value: string) => {
    setSearchTerm(value)
  }

  const searchPatients = useCallback(async () => {
    const patients = await getPatientService().searchPatients({
      searchTerm,
      ownerId: uid
    })
    setItems(patients)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  useEffect(() => {
    searchPatients()
  }, [searchTerm, searchPatients])

  const { logoutUser } = useAuth()

  return (
    <nav className="bg-gray-100 w-screen fixed top-0 z-50 mb-8">
      <div className="px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button
              type="button"
              className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="absolute -inset-0.5"></span>
              <span className="sr-only">Open main menu</span>
              <svg
                className="block h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
              <svg
                className="hidden h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex items-center justify-center sm:items-stretch sm:justify-start flex-2">
            <div className="hidden sm:block">
              <div className="flex space-x-4">
                <Link
                  to="/"
                  className={`${
                    pathname === '/' ? 'bg-gray-200' : 'hover:bg-gray-200'
                  } rounded px-3 py-2`}
                >
                  <HomeIcon className="h-5 w-5 text-blue-800" />
                </Link>
                {/* <a
                  href="#"
                  className={`${
                    pathname === '/profile'
                      ? 'bg-gray-200'
                      : 'hover:bg-gray-200'
                  } rounded px-3 py-2`}
                >
                  <UsersIcon className="h-5 w-5 text-blue-800" />
                </a>
                <a
                  href="/calendar"
                  className={`${
                    pathname === '/calendar'
                      ? 'bg-gray-200'
                      : 'hover:bg-gray-200'
                  } rounded px-3 py-2`}
                >
                  <CalendarDaysIcon className="h-5 w-5 text-blue-800" />
                </a> */}
              </div>
            </div>
          </div>
          <div className="flex flex-1 justify-center">
            <ReactSearchAutocomplete
              className="w-2/4"
              items={items}
              onSearch={handleSearchInput}
              onSelect={handleSearchSelect}
              autoFocus
            />
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            {/* <button
              type="button"
              className="relative rounded-full bg-gray-200 p-2 mr-3"
            >
              <span className="absolute -inset-1.5"></span>
              <span className="sr-only">View notifications</span>
              <BellIcon className="h-5 w-5 text-blue-800" />
            </button> */}

            <Menu>
              <MenuButton>
                <Wrap>
                  <WrapItem>
                    <Avatar name={fullName} src={photoURL} size="sm">
                      <AvatarBadge boxSize="1.25em" bg="green.500" />
                    </Avatar>
                  </WrapItem>
                </Wrap>
              </MenuButton>

              <MenuList>
                <MenuGroup title="Profile">
                  <Link to="https://sijilo.featurebase.app/" target="_blank">
                    <MenuItem>Give feedback</MenuItem>
                  </Link>
                  <Link to="/settings">
                    <MenuItem>Settings</MenuItem>
                  </Link>
                </MenuGroup>
                <MenuDivider />
                <MenuGroup>
                  <MenuItem color="red" onClick={logoutUser}>
                    Log out
                  </MenuItem>
                </MenuGroup>
              </MenuList>
            </Menu>
          </div>
        </div>
      </div>
    </nav>
  )
}
