/* eslint-disable @typescript-eslint/indent */
export const required = (value: any) => (value ? undefined : 'Required')

export const mustBeNumber = (value: any) =>
  isNaN(value) ? 'Must be a number' : undefined

export const minValue = (min: number) => (value: any) =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`

export const isValidDate = (input: string) => {
  // Define a regular expression pattern for dd.mm.yyyy format
  const datePattern = /^(\d{2})\.(\d{2})\.(\d{4})$/

  // Check if the input matches the pattern
  const match = input.match(datePattern)

  if (!match) {
    return 'Invalid date format, please use DD.MM.YYYY' // Input doesn't match the expected format
  }

  // Extract day, month, and year from the matched groups
  const day = parseInt(match[1], 10)
  const month = parseInt(match[2], 10)
  const year = parseInt(match[3], 10)

  // Create a Date object and validate the day, month, and year
  const date = new Date(year, month - 1, day)

  // Check if the date object is valid and the components match the input
  if (
    date.getDate() === day &&
    date.getMonth() === month - 1 &&
    date.getFullYear() === year
  ) {
    return undefined
  }

  return 'Invalid date format, please use DD.MM.YYYY'
}

export const composeValidators =
  (...validators: any[]) =>
  (value: any) =>
    validators?.reduce(
      (error: any, validator: any) => error || validator(value),
      undefined
    )
