import { Flex, Grid, GridItem, type GridProps } from '@chakra-ui/react'

function CenteredLayout({ children, ...props }: WithChildren<GridProps>) {
  return (
    <Grid
      templateColumns="repeat(12, 1fr)"
      gap={4}
      {...props}
      mx={{ base: 8, md: 0 }}
    >
      <GridItem colSpan={{ base: 0, md: 2 }} /> {/* Left spacing */}
      <GridItem colSpan={{ base: 12, md: 8 }}>
        <Flex flexDir="column" gap={8}>
          {children}
        </Flex>
      </GridItem>
      <GridItem colSpan={{ base: 0, md: 2 }} /> {/* Right spacing */}
    </Grid>
  )
}

export default CenteredLayout
