/* eslint-disable @typescript-eslint/indent */
import { useTranslation } from 'react-i18next'
import { getAgeAtDate, makeDate } from '../../../../../utils/dateUtils'
import {
  getColorByIndex,
  getItemsWithSummaryReview,
  toCamelCase
} from '../../../../../utils/schemaUtils'
import VisitActions from '../VisitActions'
import { type VisitDisplayProps } from '../types'
import { Divider, Tag, Badge, Flex, Box } from '@chakra-ui/react'
import { useAccount } from '../../../../../client/modules/account/AccountProvider'

export default function TimelineItem({
  visit,
  birthDate,
  onVisitSelect,
  onVisitEdit,
  onVisitDelete
}: VisitDisplayProps) {
  const { t } = useTranslation()
  const { schema } = useAccount()

  const visitSchema = getItemsWithSummaryReview(schema.visit)

  return (
    <div>
      <span className="text-xs text-gray-500">
        {visit?.isFollowUp
          ? t('app.patientDossier.viewFollowUp')
          : t('app.patientDossier.viewVisit')}{' '}
        <span className="text-xs">
          - ({getAgeAtDate(birthDate, visit.createdAt)})
        </span>
      </span>
      <Flex gap={{ base: 4, md: 12 }} flexDir={{ base: 'column', md: 'row' }}>
        <div className="flex flex-col min-w-fit">
          <span className="text-sm font-medium">
            {makeDate(visit.date ?? visit.createdAt)}
          </span>
          <VisitActions
            visitId={visit.id}
            onVisitSelect={onVisitSelect}
            onVisitEdit={onVisitEdit}
            onVisitDelete={onVisitDelete}
          />
        </div>
        <Box w="full">
          {visitSchema.map(
            (item) =>
              !!visit[toCamelCase(item)] && (
                <Flex
                  key={item}
                  gap={{ base: 0, md: 4 }}
                  flexDir={{ base: 'column', md: 'row' }}
                  p={2}
                  borderRadius="4px"
                  _hover={{
                    backgroundColor: 'gray.100'
                  }}
                >
                  <Badge
                    colorScheme={getColorByIndex(item[0])}
                    variant="outline"
                    alignContent="center"
                  >
                    {item}
                  </Badge>
                  {Array.isArray(visit[toCamelCase(item)])
                    ? visit[toCamelCase(item)].map((badgeItem: string) => (
                        // todo: improve
                        <Tag mr={1} key={badgeItem}>
                          {badgeItem}
                        </Tag>
                      ))
                    : visit[toCamelCase(item)]}
                </Flex>
              )
          )}
        </Box>
      </Flex>
      <Divider marginY={8} />
    </div>
  )
}
