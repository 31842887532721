import type { LocalStorageService } from './LocalStorageService'

export class LocalStorageServiceImpl implements LocalStorageService {
  public getStringItem(key: string): string | null {
    return global.localStorage?.getItem(key)
  }

  public getItem<T>(key: string): T | null {
    const data = this.getStringItem(key)
    return data ? (JSON.parse(data) as T) : null
  }

  public setItem<T>(key: string, data: T): void {
    global.localStorage.setItem(key, JSON.stringify(data))
  }

  public setStringItem(key: string, data: string): void {
    global.localStorage.setItem(key, data)
  }

  public removeItem(key: string): void {
    global.localStorage.removeItem(key)
  }
}
