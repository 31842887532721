import { defineMessages } from 'react-intl'

export default defineMessages({
  planExpiring: {
    id: 'dashboard.PlanExpiring.title',
    defaultMessage: 'Your plan is about to expire.'
  },
  planExpiringDescription: {
    id: 'dashboard.PlanExpiring.description',
    defaultMessage:
      'You have {remaining} days remaining, please contact us to continue using Sijilo.'
  },
  contactUs: {
    id: 'dashboard.PlanExpiring.contactUs',
    defaultMessage: 'Contact Us'
  }
})
