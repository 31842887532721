import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: {
    id: 'accountFrozen.pageTitle',
    defaultMessage: 'Account Frozen'
  },
  accountFrozen: {
    id: 'accountFrozen.accountFrozen',
    defaultMessage: 'Your account has been temporarily frozen.'
  },
  accountFrozenDescription: {
    id: 'accountFrozen.accountFrozenDescription',
    defaultMessage:
      'It could be a mistake, but it could also mean that a payment is missing!'
  },
  accountFrozenDescription2: {
    id: 'accountFrozen.accountFrozenDescription2',
    defaultMessage: 'Please contact us to resolve the issue.'
  },
  contactUs: {
    id: 'accountFrozen.contactUs',
    defaultMessage: 'Contact Us'
  }
})
