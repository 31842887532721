import { Navigate } from 'react-router-dom'
import { useAuthState } from '../AuthProvider'
import LoadingOverlay from '../../components/LoadingOverlay'

export default function GuardedRoute({ children }: any) {
  const { isAuthenticated, isLoading } = useAuthState()

  if (isLoading) {
    return <LoadingOverlay />
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />
  }

  return children
}
