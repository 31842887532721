import BasePageLayout from '../../../client/modules/common/components/BasePageLayout'

export default function MainContainer({ children }: any) {
  return (
    <BasePageLayout>
      <div className="grid sm:grid-cols-12 grid-cols-1 mt-16">
        <div className="col-span-9 z-40">{children[0]}</div>
        <div className="col-span-3">{children[1]}</div>
      </div>
    </BasePageLayout>
  )
}
