import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: {
    id: 'dashboard.pageTitle',
    defaultMessage: 'Clinic Dashboard'
  },
  latestPatients: {
    id: 'dashboard.latestPatients',
    defaultMessage: 'Latest Patients'
  },
  welcome: {
    id: 'dashboard.welcome',
    defaultMessage: 'Welcome to Sijilo.'
  },
  hereForYou: {
    id: 'dashboard.hereForYou',
    defaultMessage:
      'We are here for you. If you have any questions, please do not hesitate to contact us.'
  },
  contactUs: {
    id: 'dashboard.contactUs',
    defaultMessage: 'Contact Us'
  }
})
