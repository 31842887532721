import React, {
  createContext,
  useContext,
  useEffect,
  type ReactNode
} from 'react'
import useDoctorProfile from '../common/hooks/useDoctorProfile'
import { type DoctorType } from '../../../model/doctor'
import { useLocation, useNavigate } from 'react-router-dom'
import useAccountSubscription from './hooks/useAccountSubscription'

interface AccountContextProps extends DoctorType {
  loading: boolean
}

const AccountContext = createContext<AccountContextProps | undefined>(undefined)

export const AccountProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  return (
    <AccountContext.Provider value={{ ...useDoctorProfile() }}>
      {children}
    </AccountContext.Provider>
  )
}

export const useAccount = () => {
  const context = useContext(AccountContext)

  const { pathname } = useLocation()
  const navigate = useNavigate()

  if (context === undefined) {
    throw new Error('useAccount must be used within an AccountProvider')
  }

  const { isFreeMaxUsageReached, isPlanExpired } =
    useAccountSubscription(context)

  useEffect(() => {
    if (
      (isFreeMaxUsageReached || isPlanExpired) &&
      pathname !== '/frozen' &&
      !context.loading
    ) {
      navigate('/frozen')
    }
  }, [
    context.loading,
    isFreeMaxUsageReached,
    isPlanExpired,
    navigate,
    pathname
  ])

  return context
}
