import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'
import PatientForm from './components/PatientForm'
import { type DoctorType } from '../../../../../model/doctor'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import { type Patient } from '../../../../../model/patient'

interface Props {
  historySchema: DoctorType['schema']['history']
  isOpen: boolean
  onClose: () => void
  isEditMode?: boolean
  currentPatient?: Patient
}

export default function PatientModal({ isOpen, onClose, ...restProps }: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay backdropFilter="blur(2px) " />
      <ModalContent maxW="800px">
        <ModalHeader>
          <FormattedMessage
            {...(restProps.isEditMode
              ? messages.editPatient
              : messages.addPatient)}
          />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <PatientForm onClose={onClose} {...restProps} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
