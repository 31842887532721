import { defineMessages } from 'react-intl'

export default defineMessages({
  addVisit: {
    id: 'common.patientModal.add',
    defaultMessage: 'Add visit for {patientName}'
  },
  editVisit: {
    id: 'common.patientModal.edit',
    defaultMessage: 'Edit visit for {patientName}'
  }
})
