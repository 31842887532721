/* eslint-disable no-prototype-builtins */
import { Form } from 'react-final-form'
import { generateFormFields } from './utils'

import {
  getMemoryListService,
  getVisitService
} from '../../../../../../../services/Services'
import {
  useAppDispatch,
  useAppSelector
} from '../../../../../../../store/hooks'
import {
  addVisit,
  updateVisit
} from '../../../../../../../reducers/currentVisitsReducer'
import { useAuthState } from '../../../../../../../auth/AuthProvider'
import {
  pushStagedUpdates,
  resetStagedUpdates,
  selectStagedUpdates
} from '../../../../../../../reducers/memoryListsReducer'
import {
  Accordion,
  Button,
  Grid,
  ModalFooter,
  useToast
} from '@chakra-ui/react'
import { type FormApi } from 'final-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useAccount } from '../../../../../account/AccountProvider'
import { useRef } from 'react'

interface Props {
  isEditMode?: boolean
  currentVisitValues?: any
  isFollowUp: boolean
  sortOrder: 'ASC' | 'DESC'
  onClose: () => void
}

export default function VisitForm({
  isEditMode,
  isFollowUp,
  currentVisitValues,
  sortOrder,
  onClose
}: Props) {
  const containerRef = useRef()
  const dispatch = useAppDispatch()
  const { uid } = useAuthState()
  const toast = useToast()
  const { schema } = useAccount()
  const { patientId } = useParams()
  const navigate = useNavigate()

  const memoryListUpdates = useAppSelector(selectStagedUpdates)

  if (!patientId) {
    navigate('/')
    return
  }

  const handleVisitSubmit = async (newValues: any, form: FormApi<any, any>) => {
    if (isEditMode) {
      const updatedValues: any = { isFollowUp }

      // todo extract this logic
      for (const key in currentVisitValues) {
        if (newValues.hasOwnProperty(key)) {
          updatedValues[key] = newValues[key] // its in old and new, means it was updated
        } else {
          updatedValues[key] = null // its in old, but not in new, means it was cleared
        }
      }

      for (const key in newValues) {
        if (!currentVisitValues?.hasOwnProperty(key)) {
          updatedValues[key] = newValues[key] // its in new but not old, means it is completely new
        }
      }

      getVisitService().updateVisit(
        patientId,
        currentVisitValues.id,
        updatedValues
      )
      dispatch(updateVisit(updatedValues))
      toast({
        title: 'Visit updated.',
        status: 'success',
        duration: 5000,
        isClosable: true
      })
    } else {
      const newVisit = { ...newValues, isFollowUp }
      const newVisitResponse = await getVisitService().createVisit(
        uid,
        patientId,
        newVisit
      )
      dispatch(addVisit({ visit: newVisitResponse, sortOrder }))
      toast({
        title: 'Visit created.',
        status: 'success',
        duration: 5000,
        isClosable: true
      })
    }
    await getMemoryListService().updateUserMemoryList({
      ownerId: uid,
      updates: memoryListUpdates
    })
    dispatch(pushStagedUpdates())
    dispatch(resetStagedUpdates())
    form.reset()
    onClose()
  }

  return (
    <Form
      onSubmit={handleVisitSubmit}
      initialValues={currentVisitValues}
      render={({ handleSubmit, hasValidationErrors }) => (
        <form onSubmit={handleSubmit} className="flex flex-col justify-between">
          <Accordion allowMultiple>
            <Grid templateColumns="repeat(2, 1fr)" gap={4} dir="column">
              {schema.visit.fields.map((field) =>
                generateFormFields({
                  field,
                  subCategoryLevel: 0,
                  isFollowUp,
                  containerRef
                })
              )}
            </Grid>
          </Accordion>
          <ModalFooter mt="12px">
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="blue"
              type="submit"
              disabled={hasValidationErrors}
            >
              Save
            </Button>
          </ModalFooter>
        </form>
      )}
    />
  )
}
