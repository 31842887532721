import { useState, useEffect, useCallback } from 'react'
import { getDoctorsService } from '../../../../services/Services'
import { useAuthState } from '../../../../auth/AuthProvider'
import { type DoctorType } from '../../../../model/doctor'
import { useNavigate } from 'react-router-dom'

const INITIAL_DOCTOR_PROFILE: DoctorType = {
  isOnboarded: false,
  schemaId: null,
  schema: {
    history: {
      displayName: '',
      fields: []
    },
    visit: {
      displayName: '',
      fields: []
    }
  },
  phone: '',
  isSubscribed: false,
  visitPrice: 0,
  followUpPrice: 0,
  firstName: '',
  lastName: '',
  gender: 'M',
  planExpiresAt: 0,
  address: '',
  city: '',
  freeMaxUsage: 0,
  statistics: {
    patients: {
      total: 0,
      todayCounter: 0,
      todayDate: 0
    },
    visits: {
      total: 0,
      todayCounter: 0,
      todayDate: 0
    },
    followUps: {
      total: 0,
      todayCounter: 0,
      todayDate: 0
    }
  }
}

const useDoctorProfile = () => {
  const [doctorProfile, setDoctorProfile] = useState<DoctorType>(
    INITIAL_DOCTOR_PROFILE
  )
  const { uid } = useAuthState()

  const navigate = useNavigate()

  const [loading, setLoading] = useState<boolean>(true)

  const fetchDoctorProfile = useCallback(async () => {
    if (uid) {
      const profile = await getDoctorsService().getDoctorProfile({
        ownerId: uid
      })

      if (!profile?.isOnboarded) {
        navigate('/onboarding')
        return
      }

      setDoctorProfile(profile)
      setLoading(false)
    }
  }, [navigate, uid])

  useEffect(() => {
    fetchDoctorProfile()
  }, [fetchDoctorProfile])

  return {
    ...doctorProfile,
    loading
  }
}

export default useDoctorProfile
