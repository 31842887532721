import type { VisitService } from './VisitService'
import { type FirestoreService } from '../http/FirestoreService'
import { type StatisticsService } from '../statistics/StatisticsService'

export class VisitServiceImpl implements VisitService {
  private readonly firestoreService: FirestoreService
  private readonly statisticsService: StatisticsService

  private static readonly collectionName = 'visits'
  private static readonly parentCollectionName = 'patients'

  constructor(
    firestoreService: FirestoreService,
    statisticsService: StatisticsService
  ) {
    this.firestoreService = firestoreService
    this.statisticsService = statisticsService
  }

  async createVisit(
    ownerId: string,
    patientId: string,
    data: any
  ): Promise<any> {
    const patientRef = this.firestoreService.getDocumentRef(
      VisitServiceImpl.parentCollectionName,
      patientId
    )

    if (data.isFollowUp) {
      await this.statisticsService.updateFollowUpsStatistics(ownerId)
    } else {
      await this.statisticsService.updateVisitsStatistics(ownerId)
    }

    const newVisit = await this.firestoreService.createChildDocument(
      patientRef,
      VisitServiceImpl.collectionName,
      {
        ...data,
        createdAt: Date.now(),
        updatedAt: Date.now(),
        date: data.date ?? Date.now()
      }
    )

    return newVisit
  }

  async updateVisit(
    patientId: string,
    visitId: string,
    data: any
  ): Promise<any> {
    const patientRef = this.firestoreService.getDocumentRef(
      VisitServiceImpl.parentCollectionName,
      patientId
    )

    return await this.firestoreService.updateChildDocument(
      patientRef,
      VisitServiceImpl.collectionName,
      visitId,
      { ...data, updatedAt: Date.now() }
    )
  }

  async getVisits(patientId: string): Promise<any[]> {
    const patientRef = this.firestoreService.getDocumentRef(
      VisitServiceImpl.parentCollectionName,
      patientId
    )

    return await this.firestoreService.getChildDocuments(
      patientRef,
      VisitServiceImpl.collectionName
    )
  }

  async deleteVisit(patientId: string, visitId: string): Promise<void> {
    const patientRef = this.firestoreService.getDocumentRef(
      VisitServiceImpl.parentCollectionName,
      patientId
    )

    await this.firestoreService.deleteChildDocument(
      patientRef,
      VisitServiceImpl.collectionName,
      visitId
    )
  }
}
