import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'
import { generateVisitFields } from './utils'

import { useTranslation } from 'react-i18next'
import { useAccount } from '../../../../client/modules/account/AccountProvider'

interface Props {
  isOpen: boolean
  onClose: () => void
  values: any
}

export default function VisitView({ isOpen, onClose, values }: Props) {
  const { t } = useTranslation()
  const { schema } = useAccount()

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(2px) " />
      <ModalContent maxW="800px">
        <ModalHeader>
          <h3 className="font-bold text-lg text-gray-800">
            {values?.isFollowUp
              ? t('app.patientDossier.viewFollowUp')
              : t('app.patientDossier.viewVisit')}
          </h3>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <div className="flex flex-col justify-between h-full p-8">
            <div className="grid grid-cols-2 gap-4 w-full">
              {!!values &&
                schema.visit.fields.map((field) =>
                  generateVisitFields(field, values)
                )}
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
