import { Box, Flex, Text } from '@chakra-ui/react'

interface Props {
  media: React.ReactNode
  title: string
  description: string
  action: React.ReactNode
}

export default function Banner({ media, title, description, action }: Props) {
  return (
    <Box flex={1} p={4} borderStyle="solid" borderWidth={1} borderRadius={10}>
      <Flex
        justifyContent="space-between"
        flexDir={{ base: 'column', md: 'row' }}
        gap={4}
      >
        <Flex gap={8}>
          {media}
          <Flex flexDir="column" justifyContent="center">
            <Text fontSize="2xl" fontWeight="bold">
              {title}
            </Text>
            <Text>{description}</Text>
          </Flex>
        </Flex>

        <Flex flexDir="column" justifyContent="center">
          {action}
        </Flex>
      </Flex>
    </Box>
  )
}
