import { signInWithPopup, createUserWithEmailAndPassword } from '@firebase/auth'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { auth, googleAuthProvider } from '../../db'
import './styles.css'
import {
  Box,
  Divider,
  Flex,
  Img,
  Input,
  Link,
  useToast,
  Button
} from '@chakra-ui/react'

export default function Login() {
  const navigate = useNavigate()

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const toast = useToast()

  const handleGoogleLogin = () => {
    signInWithPopup(auth, googleAuthProvider)
      .then(() => {
        navigate('/')
        toast({
          title: 'Welcome!',
          description: 'You have successfully logged in',
          status: 'success',
          duration: 4000,
          isClosable: true
        })
      })
      .catch((error) => {
        const errorMessage = error.message

        toast({
          title: 'Something wrong happened...',
          description: errorMessage,
          status: 'error',
          duration: 4000,
          isClosable: true
        })
      })
  }

  const handleEmailSignup = async () => {
    try {
      if (password.length < 6) {
        throw new Error('Password must be at least 6 characters long')
      }

      if (password !== confirmPassword) {
        throw new Error('Passwords do not match')
      }

      await createUserWithEmailAndPassword(auth, email, password)

      toast({
        title: 'Welcome!',
        description: 'You have successfully logged in',
        status: 'success',
        duration: 4000,
        isClosable: true
      })

      navigate('/')
    } catch (error: any) {
      const errorMessage = error.message

      toast({
        title: 'Something wrong happened...',
        description: errorMessage,
        status: 'error',
        duration: 4000,
        isClosable: true
      })
    }
  }

  return (
    <div className="w-screen grid grid-cols-2">
      <Box className="leftPane"></Box>
      <Flex justifyContent="center" flexDir="column" gap={4} px={24}>
        <Flex justifyContent="center" height="40%">
          <Img src="/sijilo.png" alt="logo" />
        </Flex>

        <Input
          placeholder="Email"
          onChange={(event) => {
            setEmail(event.target.value)
          }}
        />
        <Input
          placeholder="Password"
          type="password"
          onChange={(event) => {
            setPassword(event.target.value)
          }}
        />
        <Input
          placeholder="Confirm Password"
          type="password"
          onChange={(event) => {
            setConfirmPassword(event.target.value)
          }}
        />
        <Button onClick={handleEmailSignup}>Sign up</Button>

        <Divider />

        <Flex justifyContent="center" cursor="pointer">
          <Img src="/google.png" onClick={handleGoogleLogin} />
        </Flex>
        <Flex justifyContent="center">
          <Link href="/login">Already have an account? Login here</Link>
        </Flex>
      </Flex>
    </div>
  )
}
