import { type SortOrderType } from '../../types'
import { ButtonGroup, Button, Text } from '@chakra-ui/react'

interface Props {
  sortOrder: SortOrderType
  onToggle: () => void
}

export default function SortOrderToggle({ sortOrder, onToggle }: Props) {
  return (
    <ButtonGroup size="sm" isAttached>
      <Button isActive={sortOrder === 'ASC'} onClick={onToggle}>
        <Text color="blue.500">OLD TO NEW</Text>
      </Button>
      <Button isActive={sortOrder === 'DESC'} onClick={onToggle}>
        <Text color="blue.500">NEW TO OLD</Text>
      </Button>
    </ButtonGroup>
  )
}
