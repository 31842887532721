import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'
import VisitForm from './components/VisitForm'
import { FormattedMessage } from 'react-intl'
import messages from './messages'

interface Props {
  isOpen: boolean
  onClose: () => void
  isEditMode?: boolean
  currentVisitValues?: any
  patientName: string
  sortOrder: 'ASC' | 'DESC'
  isFollowUp: boolean
}

export default function VisitFormModal({
  isOpen,
  onClose,
  patientName,
  ...restProps
}: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay backdropFilter="blur(2px)" />
      <ModalContent maxW="800px">
        <ModalHeader>
          <FormattedMessage
            {...(restProps.isEditMode ? messages.editVisit : messages.addVisit)}
            values={{ patientName }}
          />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VisitForm onClose={onClose} {...restProps} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
