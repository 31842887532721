/* eslint-disable @typescript-eslint/indent */
import { useAccount } from '../../../../../client/modules/account/AccountProvider'
import { getAgeAtDate, makeDate } from '../../../../../utils/dateUtils'
import {
  getItemsWithSummaryReview,
  toCamelCase
} from '../../../../../utils/schemaUtils'
import VisitActions from '../VisitActions'
import { type VisitDisplayProps } from '../types'
import { Td, Tr, Badge, Tag } from '@chakra-ui/react'

export default function RowItem({
  visit,
  birthDate,
  onVisitSelect,
  onVisitEdit,
  onVisitDelete
}: VisitDisplayProps) {
  const { schema } = useAccount()
  const visitSchema = getItemsWithSummaryReview(schema.visit)

  return (
    <Tr className="hover:bg-gray-100">
      {['type', 'createdAt', ...visitSchema, 'actions'].map((item) => {
        const currentKey = `${visit.id as string}-${item}`
        if (item === 'actions') {
          return (
            <Td key={currentKey}>
              <VisitActions
                visitId={visit.id}
                onVisitSelect={onVisitSelect}
                onVisitEdit={onVisitEdit}
                onVisitDelete={onVisitDelete}
              />
            </Td>
          )
        }

        if (item === 'type') {
          return (
            <Td key={currentKey}>
              <Badge colorScheme={visit.isFollowUp ? 'green' : 'blue'}>
                <span className="text-xs">
                  {visit.isFollowUp ? 'FollowUp' : 'Visit'}
                </span>
              </Badge>
            </Td>
          )
        }
        return (
          // todo: improve
          <Td key={currentKey} className="min-w-[6rem]">
            {item === 'createdAt'
              ? `${makeDate(visit.date ?? visit.createdAt)} - ${getAgeAtDate(
                  birthDate,
                  visit.createdAt
                )}`
              : Array.isArray(visit[toCamelCase(item)])
              ? visit[toCamelCase(item)].map((badgeItem: string) => (
                  <Tag mr={1} key={badgeItem}>
                    {badgeItem}
                  </Tag>
                ))
              : visit[toCamelCase(item)]}
          </Td>
        )
      })}
    </Tr>
  )
}
