import { type DoctorsService } from './doctors/DoctorsService'
import type { LocalStorageService } from './local-storage/LocalStorageService'
import { type MemoryListService } from './memory-list/MemoryListService'
import { type PatientService } from './patient/PatientService'
import { type VisitService } from './visit/VisitService'

const localStorageServiceKey = 'localStorageService'
const patientServiceKey = 'patientService'
const visitServiceKey = 'visitService'
const memoryListServiceKey = 'memoryListService'
const statisticsServiceKey = 'statisticsService'
const doctorsServiceKey = 'doctorsService'

const _services: Record<string, any> = {}

// LocalStorageService
const setLocalStorageService = (service: LocalStorageService) => {
  _services[localStorageServiceKey] = service
}

const getLocalStorageService = (): LocalStorageService => {
  return _services[localStorageServiceKey]
}

// PatientService
const setPatientService = (service: PatientService) => {
  _services[patientServiceKey] = service
}

const getPatientService = (): PatientService => {
  return _services[patientServiceKey]
}

// VisitService
const setVisitService = (service: VisitService) => {
  _services[visitServiceKey] = service
}

const getVisitService = (): VisitService => {
  return _services[visitServiceKey]
}

// MemoryListService
const setMemoryListService = (service: MemoryListService) => {
  _services[memoryListServiceKey] = service
}

const getMemoryListService = (): MemoryListService => {
  return _services[memoryListServiceKey]
}

// StatisticsService
const setStatisticsService = (service: any) => {
  _services[statisticsServiceKey] = service
}

const getStatisticsService = (): any => {
  return _services[statisticsServiceKey]
}

// DoctorsService
const setDoctorsService = (service: DoctorsService) => {
  _services[doctorsServiceKey] = service
}

const getDoctorsService = (): DoctorsService => {
  return _services[doctorsServiceKey]
}

export {
  getLocalStorageService,
  setLocalStorageService,
  setPatientService,
  getPatientService,
  setVisitService,
  getVisitService,
  setMemoryListService,
  getMemoryListService,
  setStatisticsService,
  getStatisticsService,
  setDoctorsService,
  getDoctorsService
}
