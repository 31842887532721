import { Navigate } from 'react-router-dom'
import { useAuthState } from '../AuthProvider'

export default function UnguardedRoute({ children }: any) {
  const { isAuthenticated } = useAuthState()

  if (isAuthenticated) {
    return <Navigate to="/" />
  }

  return children
}
