import { Flex, Textarea, Text } from '@chakra-ui/react'
import { Field } from 'react-final-form'

interface Props {
  name: string
  label: string
}

export default function TextareaField({ name, label }: Props) {
  return (
    <Field
      name={name}
      render={({ input, meta }) => (
        <Flex flexDir="column" gap={1}>
          <Text>{label}</Text>
          <Textarea size="xs" {...input} />
          {meta.touched && meta.error && (
            <span className="text-xs text-red-500">{meta.error}</span>
          )}
        </Flex>
      )}
    />
  )
}
