import { Button, ButtonGroup } from '@chakra-ui/react'

interface Props {
  onAddPatientClick: () => void
  onAddVisitClick?: () => void
  onAddFollowUpClick?: () => void
  isPatientSelected?: boolean
}

export default function ActionBar({
  onAddPatientClick,
  onAddVisitClick,
  onAddFollowUpClick,
  isPatientSelected = false
}: Props) {
  return (
    <ButtonGroup boxShadow="xl" rounded="xl" isAttached>
      <Button colorScheme="messenger" onClick={onAddPatientClick}>
        + Add patient
      </Button>
      {isPatientSelected && <Button onClick={onAddVisitClick}>Visit</Button>}
      {isPatientSelected && (
        <Button onClick={onAddFollowUpClick}>Follow Up</Button>
      )}
    </ButtonGroup>
  )
}
