/* eslint-disable multiline-ternary */
import { Badge, Box, Flex, Text } from '@chakra-ui/react'
import {
  ArrowDownLeftIcon,
  ArrowUpRightIcon
} from '@heroicons/react/24/outline'

interface Props {
  icon: React.ReactNode
  title: string
  value: number
  shouldDisplayDiffBadge?: boolean
  isPositive?: boolean
  difference?: number
}

export default function StatisticBox({
  icon,
  title,
  value,
  shouldDisplayDiffBadge,
  isPositive,
  difference
}: Props) {
  return (
    <Box
      flex={1}
      p={4}
      borderStyle="solid"
      borderWidth={1}
      borderRadius={10}
      _hover={{ bg: 'gray.50' }}
    >
      <Box className="h-12 w-12 text-blue-700 bg-blue-100 p-3 rounded-full">
        {icon}
      </Box>
      <Box alignItems="center" mt="12px" ml="8px">
        <Flex alignItems="center">
          <Text fontSize="3xl" fontWeight="900" mr="8px">
            {value}
          </Text>
          {shouldDisplayDiffBadge && difference ? (
            <Badge
              colorScheme={isPositive ? 'green' : 'red'}
              height="100%"
              flexDirection="row"
            >
              <Flex>
                {isPositive ? `+${difference}` : difference}

                {isPositive && (
                  <ArrowUpRightIcon className="ml-1 h-4 w-4 text-green-900" />
                )}
                {!isPositive && (
                  <ArrowDownLeftIcon className="ml-1 h-4 w-4 text-red-900" />
                )}
              </Flex>
            </Badge>
          ) : null}
        </Flex>

        <Text fontSize="md" fontWeight="200" color="gray">
          {title}
        </Text>
      </Box>
    </Box>
  )
}
