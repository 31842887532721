import { useState, useEffect, useCallback } from 'react'
import { getPatientService } from '../../../../services/Services'
import { useAuthState } from '../../../../auth/AuthProvider'
import { type Patient } from '../../../../model/patient'

const useLatestPatients = () => {
  const [latestPatients, setLatestPatients] = useState<Patient[]>([])
  const { uid } = useAuthState()

  const [loading, setLoading] = useState<boolean>(true)

  const fetchLatestPatients = useCallback(async () => {
    if (uid) {
      const patients = await getPatientService().getLatestPatients({
        ownerId: uid
      })
      setLatestPatients(patients)
      setLoading(false)
    }
  }, [uid])

  useEffect(() => {
    fetchLatestPatients()
  }, [fetchLatestPatients])

  return { latestPatients, loading }
}

export default useLatestPatients
