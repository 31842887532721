/* eslint-disable multiline-ternary */
import {
  Box,
  Button,
  Flex,
  Grid,
  Img,
  Progress,
  useToast
} from '@chakra-ui/react'
import Header from '../../../common/components/Header'
import { useEffect, useState } from 'react'
import InputField from '../../../../../components/common/form/InputField'
import { Form, useFormState } from 'react-final-form'
import { CLINIC_SPECIALIZATION_OPTIONS } from './constants'
import SelectField from '../../../../../components/common/form/SelectField'
import { getDoctorsService } from '../../../../../services/Services'
import { useAuthState } from '../../../../../auth/AuthProvider'
import { useNavigate } from 'react-router-dom'
import useDoctorProfile from '../../../common/hooks/useDoctorProfile'

interface Props {
  onNext: () => void
}

const FirstStep = ({ onNext }: Props) => {
  return (
    <Flex flexDir="column" gap={8} textAlign="center">
      <Header>Welcome to Sijilo, let&apos;s get you started!</Header>
      <Flex justifyContent="center">
        <Button color="primary" onClick={onNext}>
          Get started
        </Button>
      </Flex>
    </Flex>
  )
}

const SecondStep = ({ onNext }: Props) => {
  const { values } = useFormState()
  const { firstName, lastName, phone, address, city } = values
  const isFilled = firstName && lastName && phone && address && city

  return (
    <Flex flexDir="column" gap={8}>
      <Header>Personal Information</Header>
      <Grid gap={4} templateColumns="1fr 1fr">
        <InputField name="firstName" label="First Name" />
        <InputField name="lastName" label="Last Name" />
      </Grid>
      <InputField name="phone" label="Phone" />
      <Grid gap={4} templateColumns="1fr 1fr">
        <InputField name="address" label="Address" />
        <InputField name="city" label="City" />
      </Grid>
      {/* <RadioField name='gender' label='Gender' /> */}
      <Flex justifyContent="center">
        <Button color="primary" onClick={onNext} isDisabled={!isFilled}>
          Next
        </Button>
      </Flex>
    </Flex>
  )
}

const ThirdStep = ({ onNext }: Props) => {
  const { values } = useFormState()
  const { visitPrice, followUpPrice } = values
  const isFilled = visitPrice && followUpPrice

  return (
    <Flex flexDir="column" gap={8}>
      <Header>Payment Information</Header>
      <InputField type="number" name="visitPrice" label="Visit Price" />
      <InputField type="number" name="followUpPrice" label="Follow Up Price" />
      <Flex justifyContent="center">
        <Button color="primary" onClick={onNext} isDisabled={!isFilled}>
          Next
        </Button>
      </Flex>
    </Flex>
  )
}

const FourthStep = () => {
  const { values } = useFormState()
  const { schemaId } = values
  const isFilled = !!schemaId

  return (
    <Flex flexDir="column" gap={8}>
      <Header>Clinic Information</Header>
      <SelectField
        name="schemaId"
        options={CLINIC_SPECIALIZATION_OPTIONS}
        label="Clinic Specialization"
      />
      <Flex justifyContent="center">
        <Button color="primary" type="submit" isDisabled={!isFilled}>
          Submit
        </Button>
      </Flex>
    </Flex>
  )
}

export default function Onboarding() {
  const [step, setStep] = useState(0)
  const toast = useToast()
  const { uid } = useAuthState()
  const navigate = useNavigate()

  const doctorProfile = useDoctorProfile()

  useEffect(() => {
    if (doctorProfile.isOnboarded) {
      toast({
        title: 'You are already onboarded!',
        description: 'You are already onboarded, welcome back to Sijilo!',
        status: 'info',
        duration: 4000,
        isClosable: true
      })
      navigate('/')
    }
  })

  const handleNext = () => {
    setStep((prev) => prev + 1)
  }

  const handleDoctorProfileUpdate = async (values: any) => {
    await getDoctorsService().onboardDoctor({
      ownerId: uid,
      doctorData: values
    })
    toast({
      title: 'Successfully onboarded!',
      description:
        'Your profile has been successfully updated, welcome to Sijilo!',
      status: 'success',
      duration: 4000,
      isClosable: true
    })
    navigate('/')
  }

  const renderStep = () => {
    switch (step) {
      case 0:
        return <FirstStep onNext={handleNext} />
      case 1:
        return <SecondStep onNext={handleNext} />
      case 2:
        return <ThirdStep onNext={handleNext} />
      case 3:
        return <FourthStep />
      default:
        return <FirstStep onNext={handleNext} />
    }
  }

  return (
    <Flex justifyContent="center" height="100vh">
      <Box alignSelf="center" mx={8}>
        <Flex justifyContent="center">
          <Img src="/sijilo.png" alt="logo" width="30%" />
        </Flex>
        <Form
          onSubmit={handleDoctorProfileUpdate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {step !== 0 ? (
                <Progress size="xs" mb={4} value={(step / 3) * 100 - 10} />
              ) : null}
              {renderStep()}
            </form>
          )}
        />
      </Box>
    </Flex>
  )
}
