import MemoryListField from '../../../../../../../components/common/form/MemoryListField'
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel
} from '@chakra-ui/react'
import { toCamelCase } from '../../../../../../../utils/schemaUtils'
import InputField from '../../../../../../../components/common/form/InputField'
import TextareaField from '../../../../../../../components/common/form/TextareaField'
import { type MutableRefObject } from 'react'

interface Props {
  field: any
  subCategoryLevel: number
  isFollowUp: boolean
  containerRef: MutableRefObject<any>
}

export function generateFormFields({
  field,
  subCategoryLevel = 0,
  isFollowUp = false,
  containerRef
}: Props) {
  if (field.type === 'subcategory') {
    if (isFollowUp && field.isFollowUp) {
      // if subcategory is followup and we are in followup mode, show it as a field
      return (
        <TextareaField
          key={field.id}
          name={toCamelCase(field.displayName)}
          label={field.displayName}
        />
      )
    }
    if (
      isFollowUp &&
      !field.isFollowUp &&
      field.fields.every((a: any) => !a.isFollowUp)
    ) {
      // if subcategory is not followup and we are in followup mode, and none of its fields are followup,
      // dont show it as a field or subcategory accordion
      return null
    }
    return (
      <AccordionItem key={field.id} className="col-span-2" ref={containerRef}>
        <h2>
          <AccordionButton>
            {field.displayName}
            <AccordionIcon />
          </AccordionButton>
        </h2>

        <AccordionPanel
          className={`grid ${
            field.fields.length > 1 ? 'grid-cols-2 gap-4' : 'grid-cols-1'
          }`}
        >
          {field.fields?.map((subField: any) =>
            // recursively generate fields for subcategory
            generateFormFields({
              field: subField,
              subCategoryLevel: subCategoryLevel + 1,
              isFollowUp,
              containerRef
            })
          )}
        </AccordionPanel>
      </AccordionItem>
    )
  }
  if (field.type === 'textarea') {
    // if we are in followup mode and field is not followup, dont show it
    if (isFollowUp && !field.isFollowUp) return null
    return (
      <TextareaField
        key={field.id}
        name={toCamelCase(field.displayName)}
        label={field.displayName}
      />
    )
  }
  if (field.type === 'memoryList') {
    // if we are in followup mode and field is not followup, dont show it
    if (isFollowUp && !field.isFollowUp) return null
    return (
      <MemoryListField
        key={field.id}
        name={toCamelCase(field.displayName)}
        label={field.displayName}
        containerRef={containerRef}
      />
    )
  } else {
    // if we are in followup mode and field is not followup, dont show it
    if (isFollowUp && !field.isFollowUp) return null
    return (
      <InputField
        key={field.id}
        name={toCamelCase(field.displayName)}
        label={field.displayName}
        type={field.type}
      />
    )
  }
}
