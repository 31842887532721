import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type Patient } from '../model/patient'
import { type RootState } from '../store'

interface PatientsState {
  value: Patient[]
}

const initialState: PatientsState = {
  value: []
}

export const patientsSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    setPatients: (state, action: PayloadAction<Patient[]>) => {
      state.value = action.payload
    },
    deletePatient: (state, action: PayloadAction<string>) => {
      state.value = state.value.filter(
        (patient) => patient.id !== action.payload
      )
    },
    addPatient: (state, action: PayloadAction<Patient>) => {
      state.value = [action.payload, ...state.value]
    },
    updateCurrentPatient: (state, action: PayloadAction<Patient>) => {
      const oldPatient = state.value.find(
        (patient) => patient.id === action.payload.id
      )
      const filteredPatients = state.value.filter(
        (patient) => patient.id !== action.payload.id
      )
      state.value = [{ ...oldPatient, ...action.payload }, ...filteredPatients]
    }
  }
})

export const { setPatients, deletePatient, addPatient, updateCurrentPatient } =
  patientsSlice.actions

export const selectPatients = (state: RootState) => state.patients.value

export default patientsSlice.reducer
