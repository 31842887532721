import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from './views/Login'
import Root from './views/Root'
import GuardedRoute from './auth/GuardedRoute'
import { AuthProvider } from './auth/AuthProvider'
import UnguardedRoute from './auth/UnguardedRoute'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import Calendar from './views/Calendar'
import Signup from './views/Signup'
import { useEffect } from 'react'
import Dashboard from './client/modules/dashboard/scenes/Dashboard'
import { IntlProvider } from 'react-intl'
import AccountFrozen from './client/modules/account/scenes/AccountFrozen'
import { AccountProvider } from './client/modules/account/AccountProvider'
import Settings from './client/modules/settings/scenes/Settings'
import Onboarding from './client/modules/account/scenes/Onboarding'

function App() {
  const makeGuardedRoute = (component: JSX.Element) => (
    <GuardedRoute>{component}</GuardedRoute>
  )

  const makeUnguardedRoute = (component: JSX.Element) => (
    <UnguardedRoute>{component}</UnguardedRoute>
  )

  useEffect(() => {
    if (
      !location.hostname.includes('sijilo') &&
      !location.hostname.includes('localhost')
    ) {
      location.replace('https://sijilo.com')
    }
  }, [])

  const theme = extendTheme({
    colors: {
      blue: {
        50: '#f3f8ff',
        100: '#e9f1ff',
        200: '#c8d7ff',
        300: '#a6bcff',
        400: '#638aff',
        500: '#2563eb',
        600: '#1e4ab3'
      }
    }
  })

  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <IntlProvider locale="en-us" defaultLocale="en-us">
          <BrowserRouter>
            <AccountProvider>
              <Routes>
                <Route path="login" element={makeUnguardedRoute(<Login />)} />
                <Route path="signup" element={makeUnguardedRoute(<Signup />)} />
                <Route path="/" element={makeGuardedRoute(<Dashboard />)} />
                <Route
                  path="onboarding"
                  element={makeGuardedRoute(<Onboarding />)}
                />
                <Route
                  path="/patients/:patientId"
                  element={makeGuardedRoute(<Root />)}
                />
                <Route
                  path="calendar"
                  element={makeGuardedRoute(<Calendar />)}
                />
                <Route
                  path="frozen"
                  element={makeGuardedRoute(<AccountFrozen />)}
                />
                <Route
                  path="settings"
                  element={makeGuardedRoute(<Settings />)}
                />
              </Routes>
            </AccountProvider>
          </BrowserRouter>
        </IntlProvider>
      </AuthProvider>
    </ChakraProvider>
  )
}

export default App
