import type { StatisticsService } from './StatisticsService'
import { type FirestoreService } from '../http/FirestoreService'
import {
  getCurrentMonthNumber,
  getCurrentYear,
  isOutdatedDate
} from '../../utils/dateUtils'
import { type DoctorsService } from '../doctors/DoctorsService'

export class StatisticsServiceImpl implements StatisticsService {
  private readonly firestoreService: FirestoreService
  private readonly doctorsService: DoctorsService

  private static readonly doctorsCollectionName = 'doctor'

  constructor(
    firestoreService: FirestoreService,
    doctorsService: DoctorsService
  ) {
    this.firestoreService = firestoreService
    this.doctorsService = doctorsService
  }

  public async updatePatientStatistics(ownerId: string): Promise<any> {
    const doctorProfile = await this.doctorsService.getDoctorProfile({
      ownerId
    })

    if (!doctorProfile) {
      throw new Error('Doctor profile not found')
    }

    return await this.firestoreService.updateDocument(
      StatisticsServiceImpl.doctorsCollectionName,
      ownerId,
      {
        statistics: {
          patients: {
            [getCurrentYear()]: {
              [getCurrentMonthNumber()]:
                (doctorProfile.statistics.patients[getCurrentYear()]?.[
                  getCurrentMonthNumber()
                ] || 0) + 1
            },
            total: (doctorProfile.statistics.patients.total || 0) + 1,
            todayCounter: isOutdatedDate(
              doctorProfile.statistics.patients.todayDate
            )
              ? 1
              : (doctorProfile.statistics.patients.todayCounter || 0) + 1,
            todayDate: Date.now()
          }
        }
      }
    )
  }

  public async updateVisitsStatistics(ownerId: string): Promise<any> {
    const doctorProfile = await this.doctorsService.getDoctorProfile({
      ownerId
    })

    if (!doctorProfile) {
      throw new Error('Doctor profile not found')
    }

    return await this.firestoreService.updateDocument(
      StatisticsServiceImpl.doctorsCollectionName,
      ownerId,
      {
        statistics: {
          visits: {
            [getCurrentYear()]: {
              [getCurrentMonthNumber()]:
                (doctorProfile.statistics.visits[getCurrentYear()]?.[
                  getCurrentMonthNumber()
                ] || 0) + 1
            },
            total: (doctorProfile.statistics.visits.total || 0) + 1,
            todayCounter: isOutdatedDate(
              doctorProfile.statistics.visits.todayDate
            )
              ? 1
              : (doctorProfile.statistics.visits.todayCounter || 0) + 1,
            todayDate: Date.now()
          }
        }
      }
    )
  }

  public async updateFollowUpsStatistics(ownerId: string): Promise<any> {
    const doctorProfile = await this.doctorsService.getDoctorProfile({
      ownerId
    })

    if (!doctorProfile) {
      throw new Error('Doctor profile not found')
    }

    return await this.firestoreService.updateDocument(
      StatisticsServiceImpl.doctorsCollectionName,
      ownerId,
      {
        statistics: {
          followUps: {
            [getCurrentYear()]: {
              [getCurrentMonthNumber()]:
                (doctorProfile.statistics.followUps[getCurrentYear()]?.[
                  getCurrentMonthNumber()
                ] || 0) + 1
            },
            total: (doctorProfile.statistics.followUps.total || 0) + 1,
            todayCounter: isOutdatedDate(
              doctorProfile.statistics.followUps.todayDate
            )
              ? 1
              : (doctorProfile.statistics.followUps.todayCounter || 0) + 1,
            todayDate: Date.now()
          }
        }
      }
    )
  }
}
