import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type Patient } from '../model/patient'
import { type RootState } from '../store'

interface CurrentPatientState {
  value: Patient
}

const initialState: CurrentPatientState = {
  value: {
    ownerId: '',
    id: '',
    name: '',
    dateOfBirth: '',
    nationality: '',
    sex: 'M',
    address: '',
    phoneNumber: '',
    attachmentIds: [],
    labels: [],
    nextVisit: '',
    globalIdentifier: 0,
    governmentalId: '',
    createdAt: 0,
    updatedAt: 0,
    registeredAt: '',
    history: undefined
  }
}

export const currentPatientSlice = createSlice({
  name: 'currentPatient',
  initialState,
  reducers: {
    setCurrentPatient: (state, action: PayloadAction<Patient>) => {
      state.value = action.payload
    },
    clearCurrentPatient: (state) => {
      state.value = initialState.value
    }
  }
})

export const { setCurrentPatient, clearCurrentPatient } =
  currentPatientSlice.actions

export const selectCurrentPatient = (state: RootState) =>
  state.currentPatient.value

export default currentPatientSlice.reducer
