import type { FirestoreService } from './FirestoreService'
import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadString
} from 'firebase/storage'
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
  type DocumentReference,
  query,
  orderBy,
  where,
  getDoc,
  setDoc,
  collectionGroup,
  limit
} from 'firebase/firestore'
import { db, storage } from '../../db'
import { v4 } from 'uuid'

export class FirestoreServiceImpl implements FirestoreService {
  public async getDocuments<T>(
    collectionName: string,
    ownerId: string,
    orderByField?: string,
    order?: 'asc' | 'desc',
    limitCount?: number
  ): Promise<T[]> {
    const q = query(
      collection(db, collectionName),
      where('ownerId', '==', ownerId),
      ...(orderByField && order ? [orderBy(orderByField, order)] : []),
      ...(limitCount ? [limit(limitCount)] : [])
    )
    const snapshot = await getDocs(q)

    // const snapshot = await getDocs(collection(db, collectionName))
    const data: any[] = []

    // eslint-disable-next-line array-callback-return
    snapshot.docs.map((_data: any) => {
      data.push({
        id: _data.id,
        ..._data.data()
      })
    })

    return data
  }

  public async getChildDocuments<T>(
    parentDocRef: DocumentReference,
    collectionName: string
  ): Promise<T[]> {
    const subcollectionRef = collection(parentDocRef, collectionName)
    const q = query(subcollectionRef, orderBy('createdAt'))
    const snapshot = await getDocs(q)
    const data: any[] = []

    // eslint-disable-next-line array-callback-return
    snapshot.docs.map((_data: any) => {
      data.push({
        id: _data.id,
        ..._data.data()
      })
    })

    return data
  }

  public async searchDocumentsByTerm<T>(
    collectionName: string,
    ownerId: string,
    fieldName: string,
    searchTerm: string
  ): Promise<T[]> {
    if (
      !searchTerm ||
      searchTerm.length === 0 ||
      searchTerm === '' ||
      searchTerm.length < 2
    ) {
      return []
    }

    const q = query(
      collection(db, collectionName),
      where('ownerId', '==', ownerId),
      where(fieldName, '>=', searchTerm),
      where(fieldName, '<=', searchTerm + '\uf8ff'),
      limit(10)
    )
    const querySnapshot = await getDocs(q)

    const results: any[] = []

    querySnapshot.forEach((_data) => {
      results.push({ id: _data.id, ..._data.data() })
    })

    return results
  }

  public async createDocument<T>(
    collectionName: string,
    data?: any
  ): Promise<T> {
    const docRef = await addDoc(collection(db, collectionName), data)
    return { id: docRef.id, ...data }
  }

  public async createChildDocument<T>(
    parentDocRef: DocumentReference,
    collectionName: string,
    data?: any
  ): Promise<T> {
    const subcollectionRef = collection(parentDocRef, collectionName)
    const docRef = await addDoc(subcollectionRef, data)
    return { id: docRef.id, ...data }
  }

  public async updateDocument<T>(
    collectionName: string,
    id: string,
    data?: any
  ): Promise<T> {
    const docRef = doc(db, collectionName, id)
    await setDoc(docRef, data, { merge: true })

    return { id, ...data }
  }

  public async updateChildDocument<T>(
    parentDocRef: DocumentReference,
    collectionName: string,
    id: string,
    data?: any
  ): Promise<T> {
    const subcollectionRef = collection(parentDocRef, collectionName)
    const docRef = doc(subcollectionRef, id)
    await updateDoc(docRef, data)
    return { id, ...data }
  }

  public async deleteDocument(
    collectionName: string,
    id: string
  ): Promise<void> {
    const docRef = doc(db, collectionName, id)
    await deleteDoc(docRef)
  }

  public async deleteChildDocument(
    parentDocRef: DocumentReference,
    collectionName: string,
    id: string
  ): Promise<void> {
    const subcollectionRef = collection(parentDocRef, collectionName)
    const docRef = doc(subcollectionRef, id)
    await deleteDoc(docRef)
  }

  public async uploadFiles(files: File[]): Promise<string[]> {
    const attachmentIds: string[] = []
    for (const file of files) {
      const fileName = `${v4()}-${file.name}`
      const storageRef = ref(storage, `attachments/${fileName}`)
      await uploadBytes(storageRef, file).then(() => {
        attachmentIds.push(fileName)
      })
    }
    return attachmentIds
  }

  public async uploadImages(dataUris: string[]): Promise<string[]> {
    const attachmentIds: string[] = []
    for (const dataUri of dataUris) {
      const fileName = v4()
      const storageRef = ref(storage, `attachments/${fileName}`)
      await uploadString(storageRef, dataUri, 'data_url').then(() => {
        attachmentIds.push(fileName)
      })
    }
    return attachmentIds
  }

  public async retrieveImages(attachmentIds: string[]): Promise<string[]> {
    const attachments: string[] = []
    if (attachmentIds?.length > 0) {
      for (const attachment of attachmentIds) {
        const storageRef = ref(storage, `attachments/${attachment}`)
        const imageUrl = await getDownloadURL(storageRef)
        attachments.push(imageUrl)
      }
    }

    return attachments
  }

  public getDocumentRef(collectionName: string, id: string) {
    return doc(db, collectionName, id)
  }

  public getCollectionRef(collectionName: string, path: string[]) {
    return collection(db, collectionName, ...path)
  }

  public getCollectionGroupRef(collectionName: string) {
    return collectionGroup(db, collectionName)
  }

  public async getDocument<T>(
    collectionName: string,
    id: string
  ): Promise<T | undefined> {
    const docRef = this.getDocumentRef(collectionName, id)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      return docSnap.data() as T
    }
  }
}
