// import CalendarCore from '../../client/modules/calendar/components/CalendarCore'
import Navbar from '../../components/Navbar'
import { Box } from '@chakra-ui/react'

function Calendar() {
  return (
    <Box className="pt-16">
      <Navbar />
      {/* <CalendarCore mode="timeGrid" /> */}
    </Box>
  )
}

export default Calendar
