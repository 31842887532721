import { toCamelCase } from '../../utils/schemaUtils'

export function generatePatientFields(field: any, values: any) {
  return (
    <div key={field.id} className="mt-4">
      <h3 className="font-bold">{field.displayName}</h3>
      <p>
        {values[toCamelCase(field.displayName)]
          ? values[toCamelCase(field.displayName)]
          : '-'}
      </p>
    </div>
  )
}
