import { signInWithPopup, signInWithEmailAndPassword } from '@firebase/auth'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { auth, googleAuthProvider } from '../../db'
import './styles.css'
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Img,
  Input,
  Link,
  useToast
} from '@chakra-ui/react'

export default function Login() {
  const navigate = useNavigate()

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState('')

  const toast = useToast()

  const handleGoogleLogin = () => {
    signInWithPopup(auth, googleAuthProvider)
      .then(() => {
        toast({
          title: 'Welcome!',
          description: 'You have successfully logged in',
          status: 'success',
          duration: 4000,
          isClosable: true
        })
        navigate('/')
      })
      .catch((error) => {
        const errorMessage = error.message

        toast({
          title: 'Something wrong happened...',
          description: errorMessage,
          status: 'error',
          duration: 4000,
          isClosable: true
        })
      })
  }

  const handleEmailLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password)
      toast({
        title: 'Welcome!',
        description: 'You have successfully logged in',
        status: 'success',
        duration: 4000,
        isClosable: true
      })
    } catch (error: any) {
      const errorMessage = error.message

      toast({
        title: 'Something wrong happened...',
        description: errorMessage,
        status: 'error',
        duration: 4000,
        isClosable: true
      })
    }
  }

  return (
    <Grid
      templateColumns={{
        base: 'repeat(1, 1fr)',
        md: 'repeat(2, 1fr)'
      }}
    >
      <Box className="leftPane"></Box>
      <Flex
        justifyContent="center"
        flexDir="column"
        gap={4}
        px={{
          base: 8,
          md: 24
        }}
      >
        <Flex justifyContent="center" height="40%">
          <Img src="/sijilo.png" alt="logo" />
        </Flex>

        <Input
          placeholder="Email"
          onChange={(event) => {
            setEmail(event.target.value)
          }}
        />
        <Input
          placeholder="Password"
          type="password"
          onChange={(event) => {
            setPassword(event.target.value)
          }}
        />
        <Button onClick={handleEmailLogin}>Log in</Button>

        <Divider />

        <Flex justifyContent="center" cursor="pointer">
          <Img src="/google.png" onClick={handleGoogleLogin} />
        </Flex>
        <Flex justifyContent="center">
          <Link href="/signup">Don&apos;t have an account? Sign up here</Link>
        </Flex>
      </Flex>
    </Grid>
  )
}
