import { type DoctorType } from '../../../../model/doctor'
import {
  getCurrentMonthNumber,
  getCurrentYear,
  getLastMonthNumber,
  isOutdatedDate
} from '../../../../utils/dateUtils'

export default function useStatistics(statistics: DoctorType['statistics']) {
  const {
    visits: visitsStatistics,
    followUps: followUpsStatistics,
    patients: patientsStatistics
  } = statistics

  // totals
  const totalPatients = Number(patientsStatistics?.total) || 0
  const totalVisits = Number(visitsStatistics?.total) || 0
  const totalFollowUps = Number(followUpsStatistics?.total) || 0

  // this month
  const patientsThisMonth = patientsStatistics
    ? patientsStatistics[getCurrentYear()]
      ? patientsStatistics[getCurrentYear()][getCurrentMonthNumber()] || 0
      : 0
    : 0

  const patientsLastMonth = patientsStatistics
    ? patientsStatistics[getCurrentYear()]
      ? patientsStatistics[getCurrentYear()][getLastMonthNumber()] || 0
      : 0
    : 0

  const visitsThisMonth = visitsStatistics
    ? visitsStatistics[getCurrentYear()]
      ? visitsStatistics[getCurrentYear()][getCurrentMonthNumber()] || 0
      : 0
    : 0

  const followUpsThisMonth = followUpsStatistics
    ? followUpsStatistics[getCurrentYear()]
      ? followUpsStatistics[getCurrentYear()][getCurrentMonthNumber()] || 0
      : 0
    : 0

  // today
  const patientsToday = Number(patientsStatistics?.todayCounter) || 0
  const visitsToday = Number(visitsStatistics?.todayCounter) || 0
  const followUpsToday = Number(followUpsStatistics?.todayCounter) || 0

  // patients difference month to month
  const patientsDiffMtM = Number(patientsThisMonth) - Number(patientsLastMonth)

  return {
    totalPatients,
    totalVisits,
    totalFollowUps,
    patientsThisMonth,
    visitsThisMonth,
    followUpsThisMonth,
    patientsToday,
    visitsToday,
    followUpsToday,
    patientsDiffMtM,
    patientsLastMonth,
    isVisitsOutdated: isOutdatedDate(visitsStatistics?.todayDate),
    isFollowUpsOutdated: isOutdatedDate(followUpsStatistics?.todayDate)
  }
}
