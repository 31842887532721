import { defineMessages } from 'react-intl'

export default defineMessages({
  trialEnding: {
    id: 'dashboard.TrialEnding.title',
    defaultMessage: 'Free usage is about to run out.'
  },
  trialEndingDescription: {
    id: 'dashboard.TrialEnding.description',
    defaultMessage:
      'You have {remaining} remaining visits & follow-ups, please contact us to continue using Sijilo.'
  },
  contactUs: {
    id: 'dashboard.TrialEnding.contactUs',
    defaultMessage: 'Contact Us'
  }
})
