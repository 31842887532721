import { initializeApp } from 'firebase/app'
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
import {
  initializeFirestore,
  CACHE_SIZE_UNLIMITED,
  persistentLocalCache,
  persistentMultipleTabManager
} from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyC8y3_MkB8gOULPKZRlt7JjZBc_Cyu6lNQ',
  authDomain: 'sijilo.com',
  projectId: 'clinic-web-app',
  storageBucket: 'clinic-web-app.appspot.com',
  messagingSenderId: '598915256697',
  appId: '1:598915256697:web:0bd59c1dbea087958f9fa8',
  measurementId: 'G-MWXXWSF2LC'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Cloud Firestore and get a reference to the service
const db = initializeFirestore(app, {
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager(),
    cacheSizeBytes: CACHE_SIZE_UNLIMITED
  })
})
const storage = getStorage(app)
const auth = getAuth()
const googleAuthProvider = new GoogleAuthProvider()

export { db, storage, auth, googleAuthProvider }
