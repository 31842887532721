import { signOut } from '@firebase/auth'
import { auth } from '../../db'
import { useNavigate } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'
import { useAuthState } from '../AuthProvider'

export default function useAuth() {
  const navigate = useNavigate()
  const toast = useToast()
  const { clearUser } = useAuthState()

  async function logoutUser() {
    await signOut(auth)
    await clearUser()
    navigate('/login')
    toast({
      title: 'Successfully logged out!',
      status: 'success'
    })
  }

  return { logoutUser }
}
