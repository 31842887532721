import MainContainer from '../../components/common/MainContainer'
import CurrentPatientSidebar from '../../components/CurrentPatientSidebar'
import PatientDossier from '../../components/PatientDossier'
import { useCallback, useEffect } from 'react'
import { useAppDispatch } from '../../store/hooks'
import { getMemoryListService } from '../../services/Services'
import { useAuthState } from '../../auth/AuthProvider'
import { setMemoryLists } from '../../reducers/memoryListsReducer'

function App() {
  const dispatch = useAppDispatch()
  const { uid } = useAuthState()

  const fetchMemoryLists = useCallback(async () => {
    if (uid) {
      const memoryLists = await getMemoryListService().getUserMemoryLists({
        ownerId: uid
      })
      dispatch(setMemoryLists(memoryLists))
    }
  }, [uid, dispatch])

  useEffect(() => {
    fetchMemoryLists()
  }, [fetchMemoryLists])

  return (
    <MainContainer>
      <PatientDossier />
      <CurrentPatientSidebar />
    </MainContainer>
  )
}

export default App
