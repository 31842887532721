import CreatableSelect from 'react-select/creatable'
import { Field, useFormState } from 'react-final-form'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
  selectMemoryLists,
  stageMemoryListUpdates
} from '../../../../reducers/memoryListsReducer'
import { type MutableRefObject, useEffect, useState } from 'react'
import { type MultiValue } from 'react-select'
import { Flex, Text } from '@chakra-ui/react'

interface Props {
  name: string
  label: string
  containerRef: MutableRefObject<any>
}

export default function MemoryListField({ name, label, containerRef }: Props) {
  const dispatch = useAppDispatch()
  const { initialValues } = useFormState()

  const [currentValues, setCurrentValues] = useState<any>([])

  const memoryLists = useAppSelector(selectMemoryLists)

  const options = memoryLists[name]?.map((option) => {
    return { value: option, label: option }
  })

  const handleValuesChange = async (input: any, newValues: MultiValue<any>) => {
    const valuesToStage = newValues
      .filter((value) => value.__isNew__)
      .map((value) => value.label)
    dispatch(stageMemoryListUpdates({ name, values: valuesToStage }))

    await input.onChange(newValues.map(({ value }) => value))
    setCurrentValues(newValues)
  }

  useEffect(() => {
    if (initialValues?.[name]) {
      setCurrentValues(
        options?.filter((option) => initialValues[name]?.includes(option.value))
      )
    } else {
      setCurrentValues([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  return (
    <Field
      name={name}
      render={({ input, meta }) => (
        <Flex flexDir="column" gap={1}>
          <Text>{label}</Text>
          <CreatableSelect
            isClearable
            options={options}
            isMulti
            onChange={(newValues) => {
              handleValuesChange(input, newValues)
            }}
            menuPortalTarget={containerRef.current}
            value={currentValues}
            menuPosition="fixed"
          />
          {meta.touched && meta.error && (
            <span className="text-xs text-red-500">{meta.error}</span>
          )}
        </Flex>
      )}
    />
  )
}
