/* eslint-disable multiline-ternary */
import { Box } from '@chakra-ui/react'
import Navbar from '../../../../../components/Navbar'
import CenteredLayout from '../CenteredLayout'

interface Props {
  isCentered?: boolean
}

export default function BasePageLayout({
  children,
  isCentered
}: WithChildren<Props>) {
  return (
    <Box>
      <Navbar />
      {isCentered ? (
        <CenteredLayout top={24} pb="24px" position="relative">
          {children}
        </CenteredLayout>
      ) : (
        children
      )}
    </Box>
  )
}
