import {
  ChartBarIcon,
  ChatBubbleLeftRightIcon,
  CheckBadgeIcon,
  TrophyIcon,
  UserIcon
} from '@heroicons/react/24/outline'
import { Flex, Grid, Spinner } from '@chakra-ui/react'
import StatisticBox from './components/StatisticBox'
import useStatistics from '../../hooks/useStatistics'
import { type DoctorType } from '../../../../../model/doctor'

interface Props {
  statistics: DoctorType['statistics']
  doctorProfileLoading: boolean
}

export default function Statistics({
  statistics,
  doctorProfileLoading
}: Props) {
  const {
    totalPatients,
    totalVisits,
    totalFollowUps,
    visitsToday,
    followUpsToday,
    patientsDiffMtM,
    patientsThisMonth,
    isFollowUpsOutdated,
    isVisitsOutdated
  } = useStatistics(statistics)
  const isDifferencePositive = patientsDiffMtM > 0

  if (doctorProfileLoading) {
    return (
      <Flex justifyContent="center">
        <Spinner />
      </Flex>
    )
  }

  return (
    <Grid
      templateColumns={{
        sm: 'repeat(1, 1fr)',
        md: 'repeat(3, 1fr)',
        xl: 'repeat(5, 1fr)'
      }}
      gridGap={4}
    >
      <StatisticBox
        icon={<UserIcon />}
        title="All-time patients"
        value={totalPatients}
      />
      <StatisticBox
        icon={<ChatBubbleLeftRightIcon />}
        title="All-time visits"
        value={totalVisits + totalFollowUps}
      />
      <StatisticBox
        icon={<TrophyIcon />}
        title="Visits today"
        value={isVisitsOutdated ? 0 : visitsToday}
      />
      <StatisticBox
        icon={<CheckBadgeIcon />}
        title="Follow ups today"
        value={isFollowUpsOutdated ? 0 : followUpsToday}
      />
      <StatisticBox
        icon={<ChartBarIcon />}
        title="Patients this month"
        value={patientsThisMonth}
        shouldDisplayDiffBadge
        isPositive={isDifferencePositive}
        difference={patientsDiffMtM}
      />
    </Grid>
  )
}
