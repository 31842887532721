import { Flex, Input, Text } from '@chakra-ui/react'
import { Field } from 'react-final-form'

interface Props {
  name: string
  label: string
  type?: string
  validate?: any
}

export default function InputField({
  name,
  label,
  type = 'text',
  validate
}: Props) {
  return (
    <Field
      validate={validate}
      name={name}
      render={({ input, meta }) => (
        <Flex flexDir="column" gap={1}>
          <Text>
            {label} {validate && '*'}
          </Text>
          <Input size="sm" type={type} {...input} />
          {meta.touched && meta.error && (
            <span className="text-xs text-red-500">{meta.error}</span>
          )}
        </Flex>
      )}
    />
  )
}
