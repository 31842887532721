import { Stack, Radio, Flex, Text, RadioGroup } from '@chakra-ui/react'
import { useState } from 'react'
import { Field, useField } from 'react-final-form'

interface Props {
  name: string
  label: string
  options: Array<{ title: string; value: string }>
  validate?: any
}

export default function RadioField({ name, label, options, validate }: Props) {
  const {
    input: { value: initialValue }
  } = useField(name)
  const [currentValue, setCurrentValue] = useState<string>(initialValue)

  return (
    <Field
      name={name}
      type="radio"
      validate={validate}
      render={({ input, meta }) => (
        <Flex flexDir="column" gap={1}>
          <Text>
            {label} {validate && '*'}
          </Text>
          <RadioGroup onChange={setCurrentValue} value={currentValue}>
            <Stack direction="row">
              {options.map(({ title, value }) => (
                <Radio
                  {...input}
                  key={value}
                  name={name}
                  value={value}
                  className="checked:bg-blue-500"
                >
                  {title}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
          {meta.touched && meta.error && <span>{meta.error}</span>}
        </Flex>
      )}
    />
  )
}
