import { type DoctorType } from '../model/doctor'
import { type FieldType } from '../model/doctor/Doctor'

export function toCamelCase(displayName: string): string {
  const words = displayName.split(' ')
  let camelCase = words[0].toLowerCase()

  for (let i = 1; i < words.length; i++) {
    camelCase +=
      words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase()
  }

  return camelCase
}

export function getItemsWithSummaryReview(
  visit: DoctorType['schema']['visit']
) {
  return recursiveStep(visit.fields)
}

const recursiveStep = (fields: FieldType[]): string[] => {
  const itemsWithSummaryReview = []

  if (!fields || fields.length === 0) return []

  for (const field of fields) {
    if (field.summaryReview) {
      itemsWithSummaryReview.push(field.displayName)
    }

    const fieldItems = getItemsWithSummaryReview(field)
    itemsWithSummaryReview.push(...fieldItems)
  }

  return itemsWithSummaryReview
}

export function getColorByIndex(letter: string) {
  const colors = [
    'red',
    'orange',
    'green',
    'teal',
    'blue',
    'cyan',
    'purple',
    'pink'
  ]

  // Calculate the actual index, considering rotation
  const actualIndex = letter.charCodeAt(0) % colors.length

  // Return the color at the calculated index
  return colors[actualIndex] as any
}
