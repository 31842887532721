import { ButtonGroup, IconButton } from '@chakra-ui/react'
import { type DisplayModeType } from '../../types'

import { MdList, MdOutlineTableRows } from 'react-icons/md'
interface Props {
  displayMode: DisplayModeType
  onToggle: () => void
}

export default function DisplayModeToggle({ displayMode, onToggle }: Props) {
  return (
    <ButtonGroup size="sm" isAttached>
      <IconButton
        fontSize="20px"
        color="blue.500"
        isActive={displayMode === 'TIMELINE'}
        onClick={onToggle}
        aria-label="Timeline"
        icon={<MdList />}
      />
      <IconButton
        fontSize="20px"
        color="blue.500"
        isActive={displayMode === 'TABLE'}
        onClick={onToggle}
        aria-label="Table"
        icon={<MdOutlineTableRows />}
      />
    </ButtonGroup>
  )
}
