export function replaceElementById<T extends { id: string }>(
  arr: T[],
  id: string,
  newValue: T
): T[] {
  const indexToReplace = arr.findIndex((item) => item.id === id)

  if (indexToReplace === -1) {
    // If id is not found in the array, return the original array
    return arr
  }

  return arr.flatMap((value, i) =>
    i === indexToReplace ? [newValue] : [value]
  )
}

export const getNewestObject = (arrayOfObjects: any) => {
  if (!Array.isArray(arrayOfObjects) || arrayOfObjects.length === 0) {
    return null
  }

  return arrayOfObjects.reduce((maxDateObject, currentObject) => {
    const currentDate = new Date(currentObject.date)
    const maxDate = maxDateObject ? new Date(maxDateObject.date) : null

    if (!maxDate || currentDate > maxDate) {
      return currentObject
    } else {
      return maxDateObject
    }
  }, null)
}
