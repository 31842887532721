import { FormattedMessage } from 'react-intl'
import BasePageLayout from '../../../common/components/BasePageLayout'
import Header from '../../../common/components/Header'
import messages from './messages'
import { Form } from 'react-final-form'
import InputField from '../../../../../components/common/form/InputField'
import { Button, Divider, Flex, useToast } from '@chakra-ui/react'
import RadioField from '../../../../../components/common/form/RadioField'
import { useAccount } from '../../../account/AccountProvider'
import { getDoctorsService } from '../../../../../services/Services'
import { useAuthState } from '../../../../../auth/AuthProvider'

export default function Settings() {
  const toast = useToast()
  const { uid } = useAuthState()

  const {
    firstName,
    lastName,
    phone,
    address,
    city,
    gender,
    visitPrice,
    followUpPrice
  } = useAccount()

  const initialValues = {
    firstName,
    lastName,
    phone,
    address,
    city,
    gender,
    visitPrice,
    followUpPrice
  }

  const handleUpdateProfileClick = async (values: any) => {
    try {
      await getDoctorsService().updateDoctorProfile({
        ownerId: uid,
        doctorData: values
      })
      toast({
        title: 'Your profile has been successfully updated.',
        status: 'success',
        duration: 5000,
        isClosable: true
      })
    } catch (error) {
      toast({
        title: 'Something went wrong, please try again later',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  return (
    <BasePageLayout isCentered>
      <Header>
        <FormattedMessage {...messages.pageTitle} />
      </Header>

      <Form
        initialValues={initialValues}
        onSubmit={(values) => {
          handleUpdateProfileClick(values)
        }}
        render={({ handleSubmit, dirty }) => (
          <form onSubmit={handleSubmit} style={{ minWidth: '100%' }}>
            <Flex gap={4}>
              <InputField name="firstName" label="First Name" />
              <InputField name="lastName" label="Last Name" />
            </Flex>

            <InputField name="phone" label="Phone" />

            <Flex gap={4}>
              <InputField name="address" label="Address" />
              <InputField name="city" label="City" />
            </Flex>
            <RadioField
              name="gender"
              label="Gender"
              options={[
                { title: 'Male', value: 'M' },
                { title: 'Female', value: 'F' }
              ]}
            />

            <Divider my={4} />

            <Flex gap={4}>
              <InputField type="number" name="visitPrice" label="Visit Price" />
              <InputField
                type="number"
                name="followUpPrice"
                label="Follow Up Price"
              />
            </Flex>

            <Divider my={4} />

            <Button type="submit" colorScheme="blue" isDisabled={!dirty}>
              Save profile
            </Button>
          </form>
        )}
      />
    </BasePageLayout>
  )
}
