import { DoctorsServiceImpl } from './doctors/DoctorsServiceImpl'
import { FirestoreServiceImpl } from './http/FirestoreServiceImpl'
import { LocalStorageServiceImpl } from './local-storage/LocalStorageServiceImpl'
import { MemoryListServiceImpl } from './memory-list/MemoryListServiceImpl'
import { PatientServiceImpl } from './patient/PatientServiceImpl'
import {
  setDoctorsService,
  setLocalStorageService,
  setMemoryListService,
  setPatientService,
  setStatisticsService,
  setVisitService
} from './Services'
import { StatisticsServiceImpl } from './statistics/StatisticsServiceImpl'
import { VisitServiceImpl } from './visit/VisitServiceImpl'

const firestoreService = new FirestoreServiceImpl()
const doctorsService = new DoctorsServiceImpl(firestoreService)
const statisticsService = new StatisticsServiceImpl(
  firestoreService,
  doctorsService
)

const initServices = () => {
  setLocalStorageService(new LocalStorageServiceImpl())
  setPatientService(new PatientServiceImpl(firestoreService, statisticsService))
  setVisitService(new VisitServiceImpl(firestoreService, statisticsService))
  setMemoryListService(new MemoryListServiceImpl(firestoreService))
  setStatisticsService(statisticsService)
  setDoctorsService(new DoctorsServiceImpl(firestoreService))
}

export const serviceRegistry = {
  initServices
}
