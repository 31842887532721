import type { PatientService } from './PatientService'
import type { Patient, PatientPayload } from '../../model/patient'
import { type FirestoreService } from '../http/FirestoreService'
import { type StatisticsService } from '../statistics/StatisticsService'

export class PatientServiceImpl implements PatientService {
  private readonly firestoreService: FirestoreService
  private readonly statisticsService: StatisticsService

  private static readonly collectionName = 'patients'

  constructor(
    firestoreService: FirestoreService,
    statisticsService: StatisticsService
  ) {
    this.firestoreService = firestoreService
    this.statisticsService = statisticsService
  }

  async createPatient({
    attachments,
    captures,
    ...patient
  }: PatientPayload): Promise<Patient> {
    const attachmentIds: string[] = []
    if (attachments) {
      attachmentIds.push(
        ...(await this.firestoreService.uploadFiles(attachments))
      )
    }

    if (captures) {
      attachmentIds?.push(
        ...(await this.firestoreService.uploadImages(captures))
      )
    }

    const newPatient: Patient = await this.firestoreService.createDocument(
      PatientServiceImpl.collectionName,
      {
        ...patient,
        attachmentIds,
        createdAt: Date.now(),
        updatedAt: Date.now()
      }
    )

    await this.statisticsService.updatePatientStatistics(patient.ownerId)

    return newPatient
  }

  async updatePatient({
    attachments,
    captures,
    ...patient
  }: Patient & {
    attachments: string[]
    captures: string[]
  }): Promise<Patient> {
    const attachmentIds: string[] = [...patient.attachmentIds]
    if (attachments) {
      attachmentIds.push(
        ...(await this.firestoreService.uploadImages(attachments))
      )
    }

    if (captures) {
      attachmentIds.push(
        ...(await this.firestoreService.uploadImages(captures))
      )
    }

    return await this.firestoreService.updateDocument(
      PatientServiceImpl.collectionName,
      patient.id,
      {
        ...patient,
        attachmentIds,
        updatedAt: Date.now()
      }
    )
  }

  async getPatients({ ownerId }: { ownerId: string }): Promise<Patient[]> {
    return await this.firestoreService.getDocuments(
      PatientServiceImpl.collectionName,
      ownerId
    )
  }

  async getOnePatient({
    patientId
  }: {
    patientId: string
  }): Promise<Patient | undefined> {
    return await this.firestoreService.getDocument(
      PatientServiceImpl.collectionName,
      patientId
    )
  }

  async getLatestPatients({
    ownerId
  }: {
    ownerId: string
  }): Promise<Patient[]> {
    return await this.firestoreService.getDocuments(
      PatientServiceImpl.collectionName,
      ownerId,
      'updatedAt',
      'desc',
      5
    )
  }

  async searchPatients({
    searchTerm,
    ownerId
  }: {
    searchTerm: string
    ownerId: string
  }): Promise<Patient[]> {
    return await this.firestoreService.searchDocumentsByTerm(
      PatientServiceImpl.collectionName,
      ownerId,
      'name',
      searchTerm
    )
  }

  async getPatientAttachments(attachmentIds: string[]): Promise<string[]> {
    return await this.firestoreService.retrieveImages(attachmentIds)
  }

  async deletePatient(id: string): Promise<void> {
    await this.firestoreService.deleteDocument(
      PatientServiceImpl.collectionName,
      id
    )
  }
}
