import { Text, Flex } from '@chakra-ui/react'

export default function Header({ children }: WithChildren) {
  return (
    <Flex flexDirection="column" justifyContent="center">
      <Text fontSize="2xl" fontWeight="extrabold">
        {children}
      </Text>
    </Flex>
  )
}
