import { defineMessages } from 'react-intl'

export default defineMessages({
  addPatient: {
    id: 'common.patientModal.add',
    defaultMessage: 'Add Patient'
  },
  editPatient: {
    id: 'common.patientModal.edit',
    defaultMessage: 'Edit patient'
  }
})
