/* eslint-disable multiline-ternary */
import { Box, Divider, Flex, Spinner, Text } from '@chakra-ui/react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import Header from '../../../common/components/Header'
import { getCurrentMonthName } from '../../../../../utils/dateUtils'
import messages from './messages'

interface Props {
  doctorProfileLoading: boolean
  visitsThisMonth: number
  followUpsThisMonth: number
  visitPrice: number
  followUpPrice: number
}

export default function Finances({
  doctorProfileLoading,
  visitsThisMonth,
  followUpsThisMonth,
  visitPrice,
  followUpPrice
}: Props) {
  return (
    <Flex flex={1} flexDir="column" gap={4}>
      <Header>
        <FormattedMessage {...messages.finances} />
      </Header>
      <Box flex={1} p={4} borderStyle="solid" borderWidth={1} borderRadius={10}>
        {doctorProfileLoading ? (
          <Flex justifyContent="center">
            <Spinner />
          </Flex>
        ) : (
          <Box>
            <Text fontSize="lg" fontWeight="semibold" mb="12px">
              {getCurrentMonthName()}
            </Text>
            <Divider />
            <Flex flexDir="column" gap={4} my="12px">
              <Flex justifyContent="space-between">
                <Text>
                  Visits (<strong>{visitsThisMonth}x</strong> EGP {visitPrice})
                </Text>
                <Text fontWeight="medium">
                  EGP <FormattedNumber value={visitsThisMonth * visitPrice} />
                </Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text>
                  Follow-ups (<strong>{followUpsThisMonth}x</strong> EGP{' '}
                  {followUpPrice})
                </Text>
                <Text fontWeight="medium">
                  EGP {followUpsThisMonth * followUpPrice}
                </Text>
              </Flex>
            </Flex>
            <Divider />
            <Flex justifyContent="space-between" mt="12px">
              <Text fontSize="2xl" fontWeight="medium">
                Total
              </Text>
              <Text fontWeight="bold" fontSize="3xl">
                EGP{' '}
                <FormattedNumber
                  value={
                    visitsThisMonth * visitPrice +
                    followUpsThisMonth * followUpPrice
                  }
                />
              </Text>
            </Flex>
          </Box>
        )}
      </Box>
    </Flex>
  )
}
