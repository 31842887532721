import { configureStore } from '@reduxjs/toolkit'
import currentPatientReducer from '../reducers/currentPatientReducer'
import currentVisitsReducer from '../reducers/currentVisitsReducer'
import memoryListsReducer from '../reducers/memoryListsReducer'
import patientsReducer from '../reducers/patientsReducer'

export const store = configureStore({
  reducer: {
    patients: patientsReducer,
    visits: currentVisitsReducer,
    memoryLists: memoryListsReducer,
    currentPatient: currentPatientReducer
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
