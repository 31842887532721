import { getAuth, onAuthStateChanged } from '@firebase/auth'
import { useState, useEffect, useContext } from 'react'
import { AuthContext } from './AuthContext'
import { type User } from 'firebase/auth'

export const AuthProvider = (props: any) => {
  const [user, setUser] = useState<User | null>()
  const [error, setError] = useState()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      getAuth(),
      (currentUser) => {
        setLoading(false)
        if (currentUser) {
          setUser(currentUser)
        }
      },
      setError as any
    )
    return () => {
      unsubscribe()
    }
  }, [])

  const clearUser = () => {
    setUser(null)
  }

  return (
    <AuthContext.Provider
      value={{ user, error, loading, clearUser }}
      {...props}
    />
  )
}

export const useAuthState = () => {
  const auth = useContext(AuthContext)
  return {
    ...auth,
    ...auth?.user,
    isAuthenticated: auth?.user != null,
    isLoading: auth.loading
  }
}
