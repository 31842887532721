import { MoonIcon } from '@heroicons/react/24/outline'

interface Props {
  isPatientSelected?: boolean
}

export default function EmptyBanner({ isPatientSelected = false }: Props) {
  return (
    <div className="flex flex-row justify-center mt-24">
      <div className="flex flex-col justify-center h-full">
        <div className="flex flex-row justify-center">
          <MoonIcon className="h-12 w-12 text-blue-500 p-1 rounded-full" />
        </div>
        <span className="text-gray-500 text-lg text-center">
          It&apos;s looking empty over here
        </span>
        <span className="text-gray-400 text-sm text-center">
          {isPatientSelected
            ? 'Add a visit or a follow up from the action bar below'
            : 'Add a patient from the action bar below or find one using the search bar above'}
        </span>
      </div>
    </div>
  )
}
