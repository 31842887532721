import { connect } from 'react-redux'
import { selectCurrentPatient } from '../../reducers/currentPatientReducer'
import { type RootState } from '../../store'
import CurrentPatientSidebar from './CurrentPatientSidebar'

const mapStateToProps = (state: RootState) => ({
  currentPatient: selectCurrentPatient(state)
})

export default connect(mapStateToProps)(CurrentPatientSidebar)
