import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { serviceRegistry } from './services/ServiceRegistry'
import { store } from './store'
import { Provider } from 'react-redux'
import './i18n'
import * as serviceWorkerRegistration from './sw-registration'

serviceRegistry.initServices()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
)

serviceWorkerRegistration.register()
