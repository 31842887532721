import type { MemoryListService } from './MemoryListService'
import { type FirestoreService } from '../http/FirestoreService'
import { type UserMemoryLists } from '../../model/MemoryList'
import { arrayUnion } from 'firebase/firestore'

export class MemoryListServiceImpl implements MemoryListService {
  private readonly firestoreService: FirestoreService

  private static readonly collectionName = 'memoryLists'

  constructor(firestoreService: FirestoreService) {
    this.firestoreService = firestoreService
  }

  async updateUserMemoryList({
    ownerId,
    updates
  }: {
    ownerId: string
    updates: UserMemoryLists
  }): Promise<UserMemoryLists> {
    const newValues: any = {}
    for (const key of Object.keys(updates)) {
      newValues[key] = arrayUnion(...updates[key])
    }

    return await this.firestoreService.updateDocument(
      MemoryListServiceImpl.collectionName,
      ownerId,
      { ...newValues }
    )
  }

  async getUserMemoryLists({
    ownerId
  }: {
    ownerId: string
  }): Promise<UserMemoryLists | undefined> {
    return await this.firestoreService.getDocument(
      MemoryListServiceImpl.collectionName,
      ownerId
    )
  }
}
