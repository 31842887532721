import {
  EyeIcon,
  PencilSquareIcon,
  TrashIcon
} from '@heroicons/react/24/outline'
import PromptModal from '../../../../common/PromptModal'
import { useRef } from 'react'
import { useDisclosure } from '@chakra-ui/react'

interface Props {
  visitId: string
  onVisitSelect: (id: string) => void
  onVisitEdit: (id: string) => void
  onVisitDelete: (id: string) => void
}

export default function VisitActions({
  visitId,
  onVisitSelect,
  onVisitEdit,
  onVisitDelete
}: Props) {
  const cancelRef = useRef<any>()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleVisitSelect = () => {
    onVisitSelect(visitId)
  }

  const handleVisitEdit = () => {
    onVisitEdit(visitId)
  }

  const handleVisitDeleteClick = () => {
    onOpen()
  }

  const handleVisitDelete = () => {
    onVisitDelete(visitId)
    onClose()
  }

  return (
    <div className="flex flex-row">
      <EyeIcon
        className="h-8 w-8 text-blue-500 cursor-pointer hover:bg-gray-200 p-1 rounded-full"
        onClick={handleVisitSelect}
      />
      <PencilSquareIcon
        className="h-8 w-8 text-blue-500 cursor-pointer hover:bg-gray-200 p-1 rounded-full"
        onClick={handleVisitEdit}
      />
      <PromptModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleVisitDelete}
        cancelRef={cancelRef}
      />
      <TrashIcon
        className="h-8 w-8 text-blue-500 cursor-pointer hover:bg-gray-200 p-1 rounded-full"
        onClick={handleVisitDeleteClick}
      />
    </div>
  )
}
