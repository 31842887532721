import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { type ApexOptions } from 'apexcharts'
import { useEffect, useMemo, useState } from 'react'
import ReactApexChart from 'react-apexcharts'

export default function GrowthChart() {
  const months = Array.from({ length: 14 }, (_, i) => i) // 0 to 71 months for example
  const percentiles = useMemo(
    () => [
      // Example WHO Weight-for-Age percentiles (3rd, 15th, 50th, 85th, 97th)
      [
        2.5, 2.8, 3.0, 3.3, 3.5, 3.8, 4.0, 4.3, 4.5, 4.8, 5.0, 5.3, 5.5, 5.8,
        6.0
      ],
      [
        2.8, 3.1, 3.4, 3.7, 4.0, 4.3, 4.6, 4.9, 5.2, 5.5, 5.8, 6.1, 6.4, 6.7,
        7.0
      ],
      [
        3.1, 3.5, 3.8, 4.2, 4.5, 4.9, 5.2, 5.6, 5.9, 6.3, 6.6, 7.0, 7.3, 7.7,
        8.0
      ],
      [
        3.5, 3.9, 4.3, 4.7, 5.1, 5.5, 5.9, 6.3, 6.7, 7.1, 7.5, 7.9, 8.3, 8.7,
        9.1
      ],
      [
        4.0, 4.4, 4.9, 5.3, 5.8, 6.2, 6.7, 7.1, 7.6, 8.0, 8.5, 9.0, 9.4, 9.9,
        10.3
      ]
    ],
    []
  )

  const options: ApexOptions = {
    title: {
      text: 'Weight-for-Age Percentiles (Boys: Birth to 13 weeks)',
      align: 'left',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: undefined,
        color: '#263238'
      }
    },
    chart: {
      type: 'line',
      height: 350,
      zoom: {
        enabled: false
      }
    },
    xaxis: {
      categories: months,
      title: {
        text: 'Age (Weeks)'
      }
    },
    yaxis: {
      title: {
        text: 'Weight (KG)'
      },
      min: 1,
      max: 11
    },
    stroke: {
      width: 1
    },
    grid: {
      xaxis: {
        lines: {
          show: true
        }
      }
    }
  }

  const [series, setSeries] = useState<any>([])

  useEffect(() => {
    setSeries([
      { name: '3rd Percentile', data: percentiles[0] },
      { name: '15th Percentile', data: percentiles[1] },
      { name: '50th Percentile', data: percentiles[2] },
      { name: '85th Percentile', data: percentiles[3] },
      { name: '97th Percentile', data: percentiles[4] }
    ])
  }, [percentiles])

  return (
    <div className="p-8 pb-0">
      <div role="alert" className="alert alert-warning mb-4">
        <ExclamationTriangleIcon className="h-5 w-5" />
        <span>
          The growth charts are still under development, please ignore for now!
        </span>
      </div>
      <ReactApexChart
        options={options}
        series={series}
        height={500}
        type="line"
      />
    </div>
  )
}
