/* eslint-disable @typescript-eslint/indent */
import { type DoctorType } from '../../../../model/doctor'
import { differenceBetweenTodayAndDate } from '../../../../utils/dateUtils'

export default function useAccountSubscription({
  isSubscribed,
  statistics,
  planExpiresAt,
  freeMaxUsage
}: Pick<
  DoctorType,
  'isSubscribed' | 'statistics' | 'planExpiresAt' | 'freeMaxUsage'
>) {
  const totalVisits = statistics.visits.total + statistics.followUps.total

  const isFreeMaxUsageReached = !isSubscribed && totalVisits >= freeMaxUsage
  const isPlanExpired =
    isSubscribed && differenceBetweenTodayAndDate(planExpiresAt) <= 0

  return {
    isFreeMaxUsageReached,
    isPlanExpired
  }
}
