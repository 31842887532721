/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  CakeIcon,
  MapIcon,
  PhoneIcon,
  HashtagIcon,
  EllipsisVerticalIcon,
  PencilSquareIcon,
  TrashIcon,
  ScaleIcon,
  CalendarDaysIcon
} from '@heroicons/react/24/outline'
import { type Patient } from '../../model/patient'
import { generatePatientFields } from './utils'
import { getPatientService } from '../../services/Services'
import { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { deletePatient } from '../../reducers/patientsReducer'
import { clearCurrentPatient } from '../../reducers/currentPatientReducer'
import { toCamelCase } from '../../utils/schemaUtils'
import Header from '../../client/modules/common/components/Header'
import { getAge, makeDate } from '../../utils/dateUtils'
import {
  selectCurrentVisits,
  getLatestVisit
} from '../../reducers/currentVisitsReducer'
import {
  Divider,
  Accordion,
  Text,
  AccordionButton,
  AccordionItem,
  AccordionIcon,
  AccordionPanel,
  Flex,
  Alert,
  Stack,
  AlertTitle,
  AlertDescription,
  useToast,
  useDisclosure
} from '@chakra-ui/react'
import { useAccount } from '../../client/modules/account/AccountProvider'
import { useNavigate, useParams } from 'react-router-dom'
import PatientModal from '../../client/modules/patient/components/PatientModal'

interface Props {
  currentPatient: Patient
}

export default function CurrentPatientSidebar({ currentPatient }: Props) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const currentVisits = useAppSelector(selectCurrentVisits)
  const latestVisit = useAppSelector(getLatestVisit)

  const { patientId } = useParams()

  const { schema } = useAccount()

  const handlePatientDelete = () => {
    if (!patientId) {
      return
    }
    getPatientService()
      .deletePatient(patientId)
      .then(() => {
        dispatch(deletePatient(patientId))
        dispatch(clearCurrentPatient())
        toast({
          title: 'Patient deleted',
          status: 'success',
          duration: 4000,
          isClosable: true
        })
        navigate('/')
      })
  }

  const [images, setImages] = useState<string[]>([])

  const fetchAttachments = useCallback(async () => {
    const attachments = await getPatientService().getPatientAttachments(
      currentPatient.attachmentIds
    )
    setImages(attachments)
  }, [currentPatient])

  useEffect(() => {
    if (patientId) {
      fetchAttachments()
    }
  }, [patientId, fetchAttachments])

  if (!currentPatient || currentPatient.id === '') {
    return (
      <div className="sm:fixed border-l-2 border-gray-200 flex flex-col text-left p-4 sm:w-3/12 w-full overflow-y-scroll top-16 bottom-0">
        <div className="flex flex-row justify-between my-4">
          <Header>No patient selected</Header>
        </div>
      </div>
    )
  }

  return (
    <div className="sm:fixed border-l-2 border-gray-200 flex flex-col text-left p-4 sm:w-3/12 w-full overflow-y-scroll top-16 bottom-0">
      <div className="flex flex-row justify-between my-4">
        <Header>{currentPatient.name}</Header>
        <div className="dropdown dropdown-end">
          <label tabIndex={0}>
            <EllipsisVerticalIcon className="h-8 w-8 text-blue-500 cursor-pointer hover:bg-gray-200 p-1 rounded-full" />
          </label>
          <ul
            tabIndex={0}
            className="dropdown-content menu shadow bg-base-100 rounded-box w-52"
          >
            <li onClick={onOpen}>
              <a>
                <PencilSquareIcon className="h-5 w-5 text-blue-500" />
                Edit
              </a>
            </li>
            <li onClick={handlePatientDelete}>
              <a>
                <TrashIcon className="h-5 w-5 text-red-500" /> Delete
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="text-sm">
        <ul className="ml-1">
          <li className="mb-4 flex items-center">
            <HashtagIcon className="h-6 w-6 text-blue-500 mr-2" />
            <span>{patientId?.slice(0, 6)}</span>
          </li>

          <li className="mb-4 flex items-center">
            <CakeIcon className="h-6 w-6 text-blue-500 mr-2" />
            <span>{`${currentPatient.dateOfBirth} (${getAge(
              currentPatient.dateOfBirth
            )}) ${currentPatient.sex === 'M' ? 'Male' : 'Female'}`}</span>
          </li>
          {latestVisit && (
            <li className="mb-4 flex items-center">
              <ScaleIcon className="h-6 w-6 text-blue-500 mr-2" />
              <span>{`Weight at last visit: ${
                latestVisit.weight ? `${latestVisit.weight} KG` : 'NA'
              }`}</span>
            </li>
          )}
          {currentPatient.address && (
            <li className="mb-4 flex items-center">
              <MapIcon className="h-6 w-6 text-blue-500 mr-2" />
              <span>{currentPatient.address}</span>
            </li>
          )}
          {currentPatient.phoneNumber && (
            <li className="flex items-center mb-4">
              <PhoneIcon className="h-6 w-6 text-blue-500 mr-2" />
              <span>{currentPatient.phoneNumber}</span>
            </li>
          )}
          <li className="mb-4 flex items-center">
            <CalendarDaysIcon className="h-6 w-6 text-blue-500 mr-2" />
            <span>
              {`${currentVisits.length} visit${
                currentVisits.length > 1 ? 's' : ''
              } since ${makeDate(currentPatient.createdAt)}`}
            </span>
          </li>
        </ul>
      </div>

      {currentPatient.history && <Divider marginY={4} />}

      <PatientModal
        historySchema={schema.history}
        isEditMode
        currentPatient={currentPatient}
        isOpen={isOpen}
        onClose={onClose}
      />

      <Stack spacing={2}>
        {currentPatient.history &&
          schema.history.fields
            .filter((field) => field.isHighlighted)
            .map((field) => {
              if (!currentPatient.history[toCamelCase(field.displayName)]) {
                return null
              }
              return (
                <Alert key={field.id} status="info">
                  <AlertTitle>{field.displayName}</AlertTitle>
                  <AlertDescription>
                    {currentPatient.history[toCamelCase(field.displayName)]}
                  </AlertDescription>
                </Alert>
              )
            })}
      </Stack>

      <Flex gap={2} flexDir="column" mt={4}>
        {currentPatient.history && (
          <Accordion allowToggle>
            <AccordionItem>
              <AccordionButton>
                <Text fontSize="lg" fontWeight="bold">
                  History
                </Text>
                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel>
                {schema.history.fields.map((field) =>
                  generatePatientFields(field, currentPatient.history)
                )}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        )}
      </Flex>

      {images.length > 0 && (
        <>
          <Divider marginY={4} />
          <div>
            <div className="flex flex-row justify-between">
              <h3 className="text-xl font-extrabold mb-3">Attachments</h3>
            </div>
            <div className="grid grid-cols-3 gap-2">
              {images.map((image) => (
                <a href={image} target="_blank" key={image} rel="noreferrer">
                  <img src={image} />
                </a>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
